<template>
<div class="popup_wrap" style="width:600px;"><!-- popup_wrap : style="width:600px; height:500px;" 참고용, 개발시 제거 -->
  <button
    type="button"
    class="layer_close"
    @click="$emit('close')"
  >close</button>
  <div class="popup_cont" ><!-- popup_cont -->

    <h1 class="page_title">이메일 무단수집거부</h1>

    <div class="content_box mt10"><!-- content_box -->
      부당한 방법으로 전산망에 접속하거나 정보를 삭제·변경·유출하는 자는 관계법령에 따라 처벌을 받게 됩니다.<br>
      본 웹사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며,
      이를 위반시 정보통신망법에 의해 형사 처벌됨을 유념하시기 바랍니다.
    </div><!-- content_box // -->
    <div class="mt10 text_center">
      <a
        class="button gray lg"
        href="#"
        @click.prevent="$emit('close')"
      >닫기</a>
    </div>
  </div><!-- popup_cont -->
</div><!-- popup_wrap // -->

</template>

<script>
export default {
  name: 'EmailCollectRefusePop'
}
</script>
